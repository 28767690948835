<template>
	<div>
		<ContentHeader title="Poli" url="/poli" subTitle="Tambah Poli" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
				<div class="card-header">
					<h3 class="card-title"><div style="#007bff">Tambah Poli</div></h3>

					<div class="card-tools">
					</div>
				</div>
				<div class="card-body row">
					<div class="col-lg-2"></div>
					<div class="col-lg-8">
						<form @submit.prevent="prosesSubmit" v-if="cabang.jenis == 'pusat'">
							<div class="px-lg-2 ">
							<div class="form-group">
								<label for="nama">Nama <span class="text-danger">*</span></label>
								<input type="text" class="form-control" id="nama" name="nama" v-model="formState.nama" autocomplete="off" required="">
							</div>
							</div>
							<div class="px-lg-2 ">
							<div class="form-group">
								<label for="status">Status</label>
								<br>
								<el-switch
									v-model="formState.status"
									class="mb-2"
									active-text="Aktif"
									inactive-text="Tidak Aktif"
								>
								</el-switch>
							</div>
							</div>
							<div class="px-lg-2">
								<button type="submit" class="btn btn-primary d-flex align-items-center mt-4">
									<div>Submit</div>	
									<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
									<span class="sr-only">Loading...</span>
									</div>
								</button>
							</div>
						</form>

						<form @submit.prevent="prosesSubmitCabang" v-else>
							<div class="px-lg-2 ">
							<div class="form-group">
								<label for="poli_id">Poli <span class="text-danger">*</span></label>
								<select name="poli_id" id="poli_id" class="form-control" v-model="formStateCabang.poli_id">
									<option v-for="(row, index) in poli" :key="index" :value="row.id">{{ row.nama }}</option>
								</select>
							</div>
							</div>
							<div class="px-lg-2 ">
							<div class="form-group">
								<label for="status">Status</label>
								<br>
								<el-switch
									v-model="formStateCabang.status"
									class="mb-2"
									active-text="Aktif"
									inactive-text="Tidak Aktif"
								>
								</el-switch>
							</div>
							</div>
							<div class="px-lg-2">
								<button type="submit" class="btn btn-primary d-flex align-items-center mt-4">
									<div>Submit</div>	
									<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
									<span class="sr-only">Loading...</span>
									</div>
								</button>
							</div>
						</form>
					</div>
					<div class="col-lg-2"></div>
				</div>
				<!-- /.card-body -->
				<div class="card-footer" style="display: block;">
					
				</div>
				<!-- /.card-footer-->
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader2'

import { ref, reactive, computed, onMounted } from 'vue'
import axios from 'axios'
import Swal from 'sweetalert2'
import router from '@/router'
import store from '@/store'

const loading = ref(false)
const cabang = computed(() => store.getters['auth/cabang'])

const formState = reactive({
	'cabang_id' : cabang.value.id,
	'nama' : null,
	'status' : 0
})

const formStateCabang = reactive({
	'cabang_id' : cabang.value.id,
	'status' : 1
})

onMounted(() => {
	if(cabang.value.jenis == 'cabang') {
		getPoli()
	}
})

const poli = ref(null)
const getPoli = async () => {
	await axios.get('api/poli/getPoli')
	.then((res) => {
		poli.value = res.data
		console.log(res)
	})
}


const prosesSubmit = () => {
	loading.value = true
	axios.post(`api/poli/create`, formState)
	.then(() => {
		Swal.fire({
		title: 'Berhasil!',
		text: 'Berhasil Menambahkan Data',
		icon: 'success',
		showConfirmButton: false,
		timer: 1500
		})
		loading.value = false
		router.push('/poli')
	})
	.catch(() => {
		Swal.fire({
		title: 'Gagal!',
		text: 'Gagal menambahkan data',
		icon: 'error',
		confirmButtonText: 'Ok'
		})	
		loading.value = false
	})
}

const prosesSubmitCabang = () => {
	loading.value = true
	axios.post(`api/poli/createRelationPoli`, formStateCabang)
	.then((res) => {
		console.log(res)
		if(res.data == 'sudah ada') {
			Swal.fire({
			title: 'Info!',
			text: 'Poli sudah ada',
			icon: 'info',
			showConfirmButton: false,
			timer: 1500
			})	
			loading.value = false

		} else {
			Swal.fire({
			title: 'Berhasil!',
			text: 'Berhasil Menambahkan Data',
			icon: 'success',
			showConfirmButton: false,
			timer: 1500
			})
			loading.value = false
			router.push('/poli')
		}
	})
	.catch(() => {
		Swal.fire({
		title: 'Gagal!',
		text: 'Gagal menambahkan data',
		icon: 'error',
		confirmButtonText: 'Ok'
		})	
		loading.value = false
	})
}
</script>